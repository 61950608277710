import { compareValues } from "@helpers/arrayFuntions";
import { getCurrencyCode } from "@helpers/helper";
import { getAllCurrencyRate } from "@lib/api/general/getCurrencyInfoApi";
import { getPaymentCardByOrg } from "@lib/api/walletUser/getPaymentCardByOrg";
import { getPaymentCardByUser } from "@lib/api/walletUser/getPaymentCardByUser";
import { checkCookies, getCookie } from "cookies-next";
import { create } from "zustand";
export const DISCOUNT_CARD_BASE_ACT = 1.05;
export const DISCOUNT_CARD_BASE_RST = 1.05;
export const DISCOUNT_CARD_BASE_STY = 1.05;
export const ACTIVE = "ACTIVE";
export const CURRENCY_CODE_BASE = "USD";
export const LAN_CODE_BASE = "en-US";
export const SHORT_LAN_CODE_BASE = "en";
export const WALLET_OPTIONS = {
  discountCards: "Discount Cards",
  certificates: "Certificates",
  cashCard: "Cash Card",
};

export const PRODUCT_OPTIONS_HOTEL = { name: "Hotels", code: "HLT" };
export const PRODUCT_OPTIONS_FLIGHT = { name: "Flights", code: "FLT" };
export const PRODUCT_OPTIONS_CAR = { name: "Cars", code: "CAR" };
export const PRODUCT_OPTIONS_ACTIVITY = { name: "Activities", code: "ACT" };
export const PRODUCT_OPTIONS_TRANSFER = { name: "Transfers", code: "TRF" };
export const PRODUCT_OPTIONS_RESORT = { name: "Resorts", code: "RST" };
export const PRODUCT_OPTIONS_STAYS = { name: "Stays", code: "STY" };

export const LABEL_ONLY_DISCOUNT = "only-discount";

type walletHistoryType = {
  type: string;
  card_code: string;
  payment_card_id: number;
  mst_type_name: string;
  currency_code: string;
  payment_card_name: string;
  payment_card_redemption_price: number;
  status: string;
};

interface walletUserStoreType {
  isLoading: boolean;
  onLoaded: boolean;
  certificateByuser: PaymentCardByUserCertidaType[];
  certificateByProduct: PaymentCardByUserCertidaType[];
  certificateByOrg: PaymentCardByOrgTypeDataRespType[];
  history: walletHistoryType[];
  currentCertificateSelected: string;
  currentDiscountCardSelected: string;
  currencies: CurrencyInfoType[];
  walletOptions: string[];
  certificateByOrgDefault: PaymentCardByOrgTypeDataRespType | null;
  discountCardByOrgDefault: PaymentCardByOrgTypeDataRespType | null;
  discountCardsByUser: DiscountCardDataType[];
  discountCardsByProduct: DiscountCardDataType[];
  showModalCertificate: boolean;
  // * wallet details
  cashCardDetails: PaymentCardByUserDetailsType | null;
  certificateDetails: PaymentCardByUserCertificatedetailsType | null;
  discountCardDetails: PaymentCardByUserDetailsType | null;
  productCodeWallet: string;
  setProductCodeWallet: (value: string) => void;
  setCashCardDetails: (data: PaymentCardByUserDetailsType) => void;
  setCertificateDetails: (
    data: PaymentCardByUserCertificatedetailsType
  ) => void;
  setDiscountCardDetails: (data: PaymentCardByUserDetailsType) => void;
  // * wallet details end
  setHistory: (data: walletHistoryType) => void;
  setCertificateByuser: (data: PaymentCardByUserCertidaType[]) => void;
  setCertificateByOrg: (data: PaymentCardByOrgTypeDataRespType[]) => void;
  fetcherGetPaymentCardByUser: (productCode: string) => void;
  setIsLoading: (flag: boolean) => void;
  setCurrentCertificateSelected: (val: string) => void;
  fetcherGetPaymentCardByOrg: (data: PaymentCardByOrgApiReqType) => void;
  setCertificateByProduct: (productCode: string) => void;
  setDiscountCardsByUser: (data: DiscountCardDataType[]) => void;
  setDiscountCardsByProduct: (productCode: string) => void;
  setCurrentDiscountCardSelected: (id: string) => void;
  setShowModalCertificate: (val: boolean) => void;
  clearSelectedCertAndDisc: () => void;
}
export const useWalletStore = create<walletUserStoreType>((set, get) => ({
  certificateByuser: [],
  certificateByProduct: [],
  isLoading: true,
  history: [],
  currentCertificateSelected: "",
  currencies: [],
  walletOptions: [],
  certificateByOrg: [],
  certificateByOrgDefault: null,
  discountCardsByUser: [],
  discountCardsByProduct: [],
  currentDiscountCardSelected: "",
  discountCardByOrgDefault: null,
  showModalCertificate: false,
  cashCardDetails: null,
  certificateDetails: null,
  discountCardDetails: null,
  onLoaded: false,
  productCodeWallet: "",
  setProductCodeWallet: (val: string) => {
    set({ productCodeWallet: val });
  },
  setCertificateByOrg: (val: PaymentCardByOrgTypeDataRespType[]) => {
    set({ certificateByOrg: val });
  },
  setHistory: (obj: walletHistoryType) => {
    const { history } = get();
    const exist = history.find((f) => f.type == obj.type);

    if (exist) {
      let clearObj = history.filter((f) => f.type != obj.type);
      set({ history: [...clearObj, obj] });
    } else {
      set({ history: [...history, obj] });
    }
  },
  setIsLoading: (val: boolean) => {
    set({ isLoading: val });
  },
  setCertificateByuser: (val: PaymentCardByUserCertidaType[]) => {
    set({ certificateByuser: val });
  },
  fetcherGetPaymentCardByUser: async (productCode: string) => {
    set({ onLoaded: false });
    set({ productCodeWallet: productCode });
    const currency = String(
      checkCookies("currency_code")
        ? getCookie("currency_code")
        : CURRENCY_CODE_BASE
    );

    const { currencies } = get();

    const resp = await getPaymentCardByUser(currency);
    if (resp && resp?.length > 0) {
      const data = resp[0];
      const certificate = data.certidata?.filter(
        (f: PaymentCardByUserCertidaType) =>
          f.status == ACTIVE && f?.services.includes(productCode)
      );

      const discountCards = data.discountcarddata?.filter(
        (f) => f.status == ACTIVE && f.card_amount > 0
      );
      const cashCardDetails = data.cashcard_details;
      const certificateDetails = data.certificate_details;
      const discountCardDetails = data.discountcard_details;
      set({
        certificateByuser: certificate,
        discountCardsByUser: discountCards,
        cashCardDetails,
        certificateDetails,
        discountCardDetails,
      });
      if (currencies?.length == 0) {
        const currenciesInfo = await getAllCurrencies2();
        set({ currencies: currenciesInfo });
      }

      setTimeout(() => {
        set({ onLoaded: true });
      }, 500);
    }
    // aqui se detecta si tiene alguna opcion del wallet activada
    console.log("walletIsEnable", resp);
    if (resp && resp?.length > 1) {
      const walletOptions = resp.find((f) => f?.walletOptions?.org_site_wallet);
      if (walletOptions) {
        const options = walletOptions?.walletOptions?.org_site_wallet;
        set({ walletOptions: options?.split(",") });
      }
    }
  },
  setCurrentCertificateSelected: (val: string) => {
    set({ currentCertificateSelected: val });
  },
  fetcherGetPaymentCardByOrg: async (data: PaymentCardByOrgApiReqType) => {
    // aqui traemos todos los certificados que el usuario puede comprar
    const resp = await getPaymentCardByOrg(data);

    if (resp && resp.length > 0) {
      set({ certificateByOrg: resp });
    }
  },
  setCertificateByProduct: (productCode: string) => {
    const { currentCertificateSelected, certificateByuser, certificateByOrg } =
      get();
    // aqui se filtra todos los certificados por productCode * HTL,- FLT - CAR - ACT - TRF - RST
    const certificates = certificateByuser?.filter(
      (f) =>
        f.pro_code == productCode ||
        f.pro_code.toUpperCase().includes(productCode.toUpperCase())
    );
    set({ certificateByProduct: certificates, discountCardsByProduct: [] });
    // si el usuario no tiene ningun certificado seleccionado se selecciona el primer certificado
    const first = certificates?.find((f) => f);
    if (currentCertificateSelected) {
      const existCard = certificates.find((f) => {
        const idLocal = `${f.card_code}-${f.payment_card_id}`;
        return (
          idLocal == currentCertificateSelected &&
          f.pro_code.toUpperCase().includes(productCode.toUpperCase())
        );
      });
      if (!existCard && first) {
        const id = `${first.card_code}-${first.payment_card_id}`;
        set({ currentCertificateSelected: id });
      }
    } else {
      if (first) {
        const id = `${first.card_code}-${first.payment_card_id}`;
        set({ currentCertificateSelected: id });
      } else {
        // no existe ningun certificado que se pueda usar para este tipo de productCode
        const order = certificateByOrg?.sort(
          compareValues("payment_card_redemption_price", "desc", true)
        );
        const firstOrg = order?.find(
          (f) =>
            f.payment_product_code == productCode ||
            f.payment_product_code
              .toUpperCase()
              .includes(productCode.toUpperCase())
        );
        // entonces se selecciona un certificado por default en este caso el mas caro
        if (firstOrg) {
          set({ certificateByOrgDefault: firstOrg });
        }
      }
    }
  },
  setDiscountCardsByUser: async (data: DiscountCardDataType[]) => {
    set({ discountCardsByUser: data });
  },

  setDiscountCardsByProduct: (productCode: string) => {
    const { discountCardsByUser, currentDiscountCardSelected } = get();
    //!preguntas Alex CertificateByOrg
    // aqui se filtra todos los certificados por productCode * HTL,- FLT - CAR - ACT - TRF - RST
    const discountCards = discountCardsByUser?.filter(
      (f) =>
        (f.pro_code == productCode ||
          f.pro_code.toUpperCase().includes(productCode.toUpperCase())) &&
        Number(f.card_amount) > 0
    );
    set({ discountCardsByProduct: discountCards, certificateByProduct: [] });

    const first = discountCards?.find((f) => f);
    if (currentDiscountCardSelected) {
      const existCard = discountCards.find((f) => {
        const idLocal = `${f.card_code}-${f.payment_card_id}`;
        return (
          idLocal == currentDiscountCardSelected &&
          f.pro_code.toUpperCase().includes(productCode.toUpperCase())
        );
      });
      if (!existCard && first) {
        const id = `${first.card_code}-${first.payment_card_id}`;
        set({ currentDiscountCardSelected: id });
      }
    } else {
      if (first) {
        const id = `${first.card_code}-${first.payment_card_id}`;
        set({
          currentDiscountCardSelected: id,
        });
      }
    }
  },
  setCurrentDiscountCardSelected: async (id: string) => {
    set({ currentDiscountCardSelected: id });
  },
  setShowModalCertificate: async (val: boolean) => {
    set({ showModalCertificate: val });
  },
  setCashCardDetails: async (val: PaymentCardByUserDetailsType) => {
    set({ cashCardDetails: val });
  },
  setCertificateDetails: async (
    val: PaymentCardByUserCertificatedetailsType
  ) => {
    set({ certificateDetails: val });
  },
  setDiscountCardDetails: async (val: PaymentCardByUserDetailsType) => {
    set({ discountCardDetails: val });
  },
  clearSelectedCertAndDisc() {
    set({ currentCertificateSelected: "", currentDiscountCardSelected: "" });
  },
}));

const getAllCurrencies2 = async () => {
  const currenciesInfo = [] as CurrencyInfoType[];
  try {
    const dataResponse = await getAllCurrencyRate({
      fromCurrency: CURRENCY_CODE_BASE,
      toCurrency: getCurrencyCode(),
      all: true,
    });
    dataResponse?.roes.map((currency) => {
      currenciesInfo.push({
        code: currency.currency.toUpperCase(),
        rate: currency.rate,
      });
    });
  } catch (error) {
    console.error(error);
  }
  return currenciesInfo;
};

export const calculatePriceWithCertificate = (
  price: number,
  certificatePrice: number,
  certificateCurrency: string,
  currentCurrencyCode: string,
  currencies: CurrencyInfoType[]
) => {
  const findCurrency = currencies?.find(
    (f) => f.code == String(currentCurrencyCode).toUpperCase()
  );
  const rate = findCurrency?.rate;
  if (certificateCurrency == CURRENCY_CODE_BASE) {
    let total = price - certificatePrice;
    // se retornal el total en la moneda base
    total = total > 0 ? total : 0;
    if (currentCurrencyCode == CURRENCY_CODE_BASE) {
      return total;
    }
    // se convierte el total a la moneda actual
    if (Number(rate) > 0) {
      return total * Number(rate);
    }
  } else {
    // si la moneda del certificado es diferente a la moneda base se debe de convertir a dolares
    const findCurrencyCertificate = currencies?.find(
      (f) => f.code == String(certificateCurrency).toUpperCase()
    );
    if (findCurrencyCertificate) {
      const rateExchange = findCurrencyCertificate?.rate;
      if (Number(rateExchange) > 0) {
        // se convierte a dolares el precio del certificado
        const certificatePriceExchangeRate =
          certificatePrice / Number(rateExchange);
        let total = price - certificatePriceExchangeRate;
        total = total > 0 ? total : 0;
        return total;
      }
    }
  }
  return price;
};
export const calculatePriceWithDiscountCard = (
  b2bMarkup: number, // unit_price
  b2b: number, // feed_cost
  discountCardAmount: number, // 250
  discountCardCurrency: string, // DLL
  currentCurrencyCode: string,
  currencies: CurrencyInfoType[],
  productCode: string
) => {
  const currentCurrency = currencies?.find(
    (f) => f.code == String(currentCurrencyCode).toUpperCase()
  );
  let discountCardAmountToBase = discountCardAmount;
  // si la moneda del discount card no es dolar se tiene que convertir a dolares
  if (discountCardCurrency != CURRENCY_CODE_BASE) {
    const findCurrencyCertificate = currencies?.find(
      (f) => f.code == String(discountCardCurrency).toUpperCase()
    );
    if (findCurrencyCertificate) {
      const rateExchange = findCurrencyCertificate?.rate;
      if (Number(rateExchange) > 0) {
        // se convierte a dolares el precio del certificado
        discountCardAmountToBase = discountCardAmount / Number(rateExchange);
      }
    }
  }
  let discountCardBase = DISCOUNT_CARD_BASE_RST;
  if (productCode == PRODUCT_OPTIONS_ACTIVITY.code) {
    discountCardBase = DISCOUNT_CARD_BASE_ACT;
  } else if (productCode == PRODUCT_OPTIONS_STAYS.code) {
    discountCardBase = DISCOUNT_CARD_BASE_STY;
  }
  let total = 0;
  const formula = b2bMarkup - b2b * discountCardBase;
  const maxDiscount = Math.min(discountCardAmountToBase, formula);
  if (maxDiscount > 0) {
    total = b2bMarkup - maxDiscount;
  } else {
    total = b2bMarkup;
  }

  /* console.log("🚀 ~ file: walletUserStore.ts:293 ~ formula:", formula);
  console.log("🚀 ~ file: walletUserStore.ts:293 ~ b2bMarkup:", b2bMarkup);
  console.log("🚀 ~ file: walletUserStore.ts:293 ~ b2b:", b2b);
  console.log(
    "🚀 ~ file: walletUserStore.ts:293 ~ discountCardAmountToBase:",
    discountCardAmountToBase
  );
  console.log("🚀 ~ file: walletUserStore.ts:293 ~ maxDiscount:", maxDiscount);
  console.log("🚀 ~ file: walletUserStore.ts:293 ~ total:", total); */
  if (currentCurrencyCode != CURRENCY_CODE_BASE && currentCurrency) {
    return total * Number(currentCurrency?.rate);
  }

  return total;
};

export const exchangeRateToUSD = (price: number, currentCurrencyTo: string) => {
  const { currencies } = useWalletStore.getState();

  if (currentCurrencyTo.toUpperCase() == CURRENCY_CODE_BASE.toUpperCase()) {
    return price;
  }
  const currentCurrency = currencies?.find(
    (f) =>
      String(f.code).toLocaleUpperCase() ==
      String(currentCurrencyTo).toUpperCase()
  );
  if (currentCurrency) {
    const rateExchange = currentCurrency?.rate;
    if (Number(rateExchange) > 0) {
      // se convierte a dolares
      return price / Number(rateExchange);
    }
  }
  return price;
};

//* this function is used to check if the certificate is enabled or not
//* If the certificate is enabled, then is neccessary that the user has the
//* certificate in his wallet.

export const isBookAllowed = (productCode: string) => {
  const {
    currentCertificateSelected,
    certificateByOrgDefault,
    discountCardsByProduct,
    certificateByOrg,
  } = useWalletStore.getState();

  const isEnabledCersProduct = isEnabledWalletOption(
    WALLET_OPTIONS.certificates
  );
  const isActiveDiscounts = discountCardsByProduct.length > 0; // * this means that wallaet shows is discount cards

  // if is active discount cards always is true
  if (isActiveDiscounts) {
    return true;
  }

  if (isEnabledCersProduct) {
    if (currentCertificateSelected) {
      return true; // * this means that the user has selected a certificate
    }

    const orgHaveCertificateByproduct = certificateByOrg.some(
      (f) => f.payment_product_code == productCode
    );
    //if not exist  some cert to product code
    if (!orgHaveCertificateByproduct) {
      return true;
    }

    if (certificateByOrgDefault) {
      useWalletStore.setState({ showModalCertificate: true }); // * open the modal to select a certificate
      return false; // * this means that the user has not selected a certificate
    }
  }
  return true; // * this means  that site no has enabled the certificates
};

export const getWalletUserCodes = () => {
  const {
    currentCertificateSelected,
    currentDiscountCardSelected,
    certificateByProduct,
    discountCardsByProduct,
  } = useWalletStore.getState();
  let wallet: {
    certificateCode?: string;
    discountCardCode?: string;
  } = {
    certificateCode: undefined,
    discountCardCode: undefined,
  };
  if (currentCertificateSelected) {
    const findCert = certificateByProduct.find((f) => {
      const id = `${f.card_code}-${f.payment_card_id}`;
      return id == currentCertificateSelected;
    });
    if (findCert) {
      wallet.certificateCode = findCert.card_code;
    }
  }
  if (currentDiscountCardSelected) {
    const findCert = discountCardsByProduct.find((f) => {
      const id = `${f.card_code}-${f.payment_card_id}`;
      return id == currentDiscountCardSelected;
    });
    if (findCert) {
      wallet.discountCardCode = findCert.card_code;
    }
  }
  return wallet;
};

export const getDiscountCardSelectedInfo = () => {
  const { currentDiscountCardSelected, discountCardsByProduct } =
    useWalletStore.getState();
  const discountCardSelected = getDiscountCardInfo(
    discountCardsByProduct,
    currentDiscountCardSelected
  );
  return discountCardSelected;
};

export const getDiscountCardInfo = (
  data: DiscountCardDataType[],
  currentSelection: string
) => {
  return data?.find(
    (f) => `${f.card_code}-${f.payment_card_id}` == currentSelection
  );
};
export const getCertificateInfo = (
  data: PaymentCardByUserCertidaType[],
  currentSelection: string
) => {
  return data?.find(
    (f) => `${f.card_code}-${f.payment_card_id}` == currentSelection
  );
};

export const getCertificateSelectedInfo = () => {
  const { certificateByProduct, currentCertificateSelected } =
    useWalletStore.getState();
  const certificate = getCertificateInfo(
    certificateByProduct,
    currentCertificateSelected
  );
  return certificate;
};

export const isEnabledWalletOption = (option: string) => {
  const { walletOptions } = useWalletStore.getState();

  return walletOptions?.some((f) => f == option);
};

export const isEnabledWalletOptionByCode = (
  option: string,
  productCode: string
) => {
  const {
    walletOptions,
    certificateByuser,
    discountCardsByUser,
    certificateByOrg,
  } = useWalletStore.getState();

  let exists = discountCardsByUser?.some(
    (s) =>
      (s.pro_code == productCode ||
        s.pro_code.toUpperCase().includes(productCode.toUpperCase())) &&
      Number(s.card_amount) > 0
  );

  const walletEnabled = walletOptions?.some((f) => f == option);

  if (option == WALLET_OPTIONS.certificates) {
    exists = certificateByuser?.some(
      (s) =>
        s.pro_code == productCode ||
        s.pro_code.toUpperCase().includes(productCode.toUpperCase())
    );
    const existsInOrg = certificateByOrg?.some(
      (s) =>
        s.payment_product_code == productCode ||
        s.payment_card_currency
          .toUpperCase()
          .includes(productCode.toUpperCase())
    );

    return walletEnabled && (exists || existsInOrg);
  }

  return walletEnabled && exists;
};

export const getEnableWalletDiscount = (productCode: string) => {
  const { discountCardsByUser, currentDiscountCardSelected } =
    useWalletStore.getState();

  let exists = discountCardsByUser?.some(
    (s) =>
      s.pro_code == productCode ||
      s.pro_code.toUpperCase().includes(productCode.toUpperCase())
  );
  if (exists && currentDiscountCardSelected) {
    return true;
  }
  return false;
};

export const checkEnabledWallet = () => {
  const certificates = isEnabledWalletOption(WALLET_OPTIONS.certificates);
  const discountCards = isEnabledWalletOption(WALLET_OPTIONS.discountCards);
  const cashCard = isEnabledWalletOption(WALLET_OPTIONS.cashCard);

  return certificates || discountCards || cashCard;
};

// this method is used to check if the certificates are enabled
export const getCertificateSelectedInfoValidate = () => {
  const {
    certificateByProduct,
    currentCertificateSelected,
    certificateByOrgDefault, // * this certificate info is per default only used if no exist a certificate
  } = useWalletStore.getState();

  const certificate = getCertificateInfo(
    certificateByProduct,
    currentCertificateSelected
  ); // * this is the certificate selected by the user

  // *  check if the user has a discount card
  const discountCard = getDiscountCardSelectedInfo();
  return (!!certificate || certificateByOrgDefault) && !discountCard;
};

export const checkEnabledCertificateWallet = () => {
  const enabled = isEnabledWalletOption(WALLET_OPTIONS.certificates);
  return enabled;
};
export const checkEnabledDiscountCardWallet = () => {
  const enabled = isEnabledWalletOption(WALLET_OPTIONS.discountCards);
  return enabled;
};

export const checkEnabledCashCardWallet = () => {
  const enabled = isEnabledWalletOption(WALLET_OPTIONS.cashCard);
  return enabled;
};

export const priceToDollarConvert = (
  price: number,
  currency: string,
  currentCurrencyTo: string
) => {
  const { currencies } = useWalletStore.getState();
  const priceDollar = Number(exchangeRateToUSD(price, currency).toFixed(2));

  if (CURRENCY_CODE_BASE == currentCurrencyTo) {
    return priceDollar;
  }
  const currentCurrency = currencies?.find(
    (f) =>
      String(f.code).toLocaleUpperCase() ==
      String(currentCurrencyTo).toUpperCase()
  );
  if (currentCurrency) {
    const rateExchange = currentCurrency?.rate;
    if (Number(rateExchange) > 0) {
      // se convierte a currency del discount card
      return priceDollar * Number(rateExchange);
    }
  }
  return priceDollar;
};
