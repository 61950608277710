import axios from "axios";

type upgradeToMembershipProceedbookType = {
  booking_id: string;
  lang_code: string;
  hash: string;
  charge_org: boolean;
};
export const getUpgradeMembershipProceedBook = async (
  data: upgradeToMembershipProceedbookType
): Promise<UpgrageToMemberShipProceedBookResponse | null> => {
  try {
    const response = await axios.post(
      `/api/membershipUpgrade/procedBook`,
      data
    );
    return response?.data;
  } catch (error) {
    console.info(error);
    return null;
  }
};
