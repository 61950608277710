import axios from "axios";

export const removeCacheUserInfo = async (userId: string) => {
  try {
    const res = await axios.post(`/api/profile/clearUserCache`, {
      userId,
    });
    return res.data;
  } catch (error) {
    return null;
  }
};
