import axios from "axios";

export const getSubscriptionPlan = async (
  enc?: string
): Promise<PlansApiType | null> => {
  try {
    const response = await axios.post(`/api/plans/getPlans`, { enc: enc });
    return response?.data;
  } catch (error) {
    console.info(error);
    return null;
  }
};
