import { getExternalConfig } from "@lib/api/layout";
import { create } from "zustand";
import weekDayNumber from "@data/calendarStartWeekDay/weekDayNumber.json";
import { getTodayDate } from "@helpers/helperAds";
import { uuidv4 } from "@lib/helpers";
import { setIsEnableLoaderGPT } from "@helpers/loader";

interface LayoutStoreState {
  currencyRate?: number | null;
  footer?: FooterApiType | null;
  loaded: boolean;
  config: ConfigApiType | null;
  isLoadedCheckLogin: boolean;
  nationality: string;
  externalConfig?: DomainsConfigType | null;
  countryList: CountryListType[];
  productMenuSelected: string;
  productSubmenuSelected: string;
  isLoadedExternalConfig: boolean;
  weekStartsOn: 0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined;
  adsCheckinDate: string;
  refreshConfig: string;
  showUpgradeUserModal: boolean;
  displayPrice: string;
  can_user_book: boolean;
  showUserDemoModal: boolean;
  showSubmenu: boolean;
  setShowSubmenu: (value: boolean) => void;
  setDisplayPrice: (val: string) => void;
  setShowUpgradeUserModal: (value: boolean) => void;
  setAdsCheckinDate: (date: string) => void;
  setWeekStartsOn: (val: 0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined) => void;
  setProductMenuSelected: (value: string) => void;
  setProductSubmenuSelected: (value: string) => void;
  setCurrencyRate: (value: number | null) => void;
  setFooter: (value: FooterApiType) => void;
  setLoaded: (value: boolean) => void;
  setConfig: (value: ConfigApiType | null) => void;
  setIsLoadedCheckLogin: (value: boolean) => void;
  setNationality: (value: string) => void;
  setExternalConfig: (value: DomainsConfigType | null) => void;
  fetcherExternalConfig: (orgId?: number) => void;
  setCountryList: (value: CountryListType[]) => void;
  fetcherUpdateWeekStartOn: (value: string) => void;
  setRefreshConfig: () => void;
  setCan_user_book: (value: boolean) => void;
  setShowUserDemoModal: (value: boolean) => void;
}

export const useLayoutStore = create<LayoutStoreState>((set) => ({
  currencyRate: null,
  footer: null,
  loaded: false,
  config: null,
  isLoadedCheckLogin: false,
  nationality: "",
  externalConfig: null,
  countryList: [],
  productMenuSelected: "",
  productSubmenuSelected: "",
  isLoadedExternalConfig: false,
  weekStartsOn: 0,
  adsCheckinDate: getTodayDate(),
  refreshConfig: "",
  showUpgradeUserModal: false,
  displayPrice: "PNT",
  can_user_book: false,
  showUserDemoModal: false,
  showSubmenu: false,
  setShowSubmenu: (flag: boolean) => set({ showSubmenu: flag }),
  setDisplayPrice: (value: string) => {
    set({ displayPrice: value });
  },
  setShowUpgradeUserModal: (flag: boolean) =>
    set({ showUpgradeUserModal: flag }),
  setAdsCheckinDate: (value: string) => set({ adsCheckinDate: value }),
  setWeekStartsOn: (value: 0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined) =>
    set({ weekStartsOn: value }),
  setProductMenuSelected: (value: string) =>
    set({ productMenuSelected: value }),
  setProductSubmenuSelected: (value: string) =>
    set({ productSubmenuSelected: value }),
  setCurrencyRate: (value: number | null) => set({ currencyRate: value }),
  setFooter: (value: FooterApiType) => set({ footer: value }),
  setLoaded: (value: boolean) => set({ loaded: value }),
  setConfig: (value: ConfigApiType | null) => set({ config: value }),
  setIsLoadedCheckLogin: (value: boolean) => set({ isLoadedCheckLogin: value }),
  setNationality: (value: string) => set({ nationality: value }),
  setExternalConfig: (value: DomainsConfigType | null) => {
    const isEnabledLoaderGpt = !!value?.showCityGpt;
    setIsEnableLoaderGPT(isEnabledLoaderGpt);
    set({ externalConfig: value, isLoadedExternalConfig: true });
  },
  fetcherExternalConfig: async (orgId?: number) => {
    if (Number(orgId) > 0) {
      getExternalConfig(orgId).then((data) => {
        const isEnabledLoaderGpt = !!data?.showCityGpt;
        setIsEnableLoaderGPT(isEnabledLoaderGpt);
        set({ externalConfig: data, isLoadedExternalConfig: true });
      });
    }
  },
  fetcherUpdateWeekStartOn: (value: string) => {
    let dayNameShort = value ? value?.split("-")[0] : "Sun";

    weekDayNumber?.weekDay?.map((obj: any) => {
      if (obj?.day == dayNameShort) {
        set({ weekStartsOn: obj?.weekDayNumber || 0 });
      }
    });
  },
  setCountryList: (value: CountryListType[]) => set({ countryList: value }),
  setRefreshConfig: () => set({ config: null, refreshConfig: uuidv4() }),
  setCan_user_book: (value: boolean) => set({ can_user_book: value }),
  setShowUserDemoModal: (value: boolean) => set({ showUserDemoModal: value }),
}));
