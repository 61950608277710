import axios from "axios";

type renewalPlanMembershipType = {
  is_autorenewal: number;
  subs_plan_id: number;
};
export const getUpgradeMembershipRenewPlan = async (
  data: renewalPlanMembershipType
): Promise<UpgradeToMembershipRenewalPlanResponseType | null> => {
  try {
    const response = await axios.post(`/api/membershipUpgrade/renewPlan`, data);
    return response?.data;
  } catch (error) {
    console.info(error);
    return null;
  }
};
