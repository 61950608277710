import axios from "axios";

type upgradeToMembershipProceedbookType = {
  booking_id: string;
  language_code: string;
  is_oxxo: string;
  is_trial: boolean;
  caller?: string;
  charge_org?: boolean;
  is_renewal?: boolean;
};
export const getPlanProceedBook = async (
  data: upgradeToMembershipProceedbookType
): Promise<UpgrageToMemberShipProceedBookResponse | null> => {
  try {
    const response = await axios.post(`/api/plans/proceedbook`, data);
    return response?.data;
  } catch (error) {
    console.info(error);
    return null;
  }
};
